import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { DefaultResponse } from 'src/shared/models';
import { addAuthorizationHeaderWithBearer } from 'src/shared/api/utils';

import {
  GetIssuersResponse,
  CreateIssuerRequest,
  UpdateIssuerRequest,
  CreateIssuerForCompanyRequest,
} from '../models';

export const ISSUERS_API = 'ISSUERS_API';

const issuersApi = createApi({
  reducerPath: ISSUERS_API,
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: process.env.REACT_APP_TEMPLATES_URL,
      prepareHeaders: (headers) => addAuthorizationHeaderWithBearer(headers),
    }),
    {
      maxRetries: 0,
    }
  ),
  tagTypes: ['ISSUERS'],
  endpoints: (builder) => ({
    getIssuers: builder.query<GetIssuersResponse, void>({
      query: () => {
        return {
          method: 'GET',
          url: 'credential/issuers',
        };
      },
      providesTags: ['ISSUERS'],
    }),
    createIssuer: builder.mutation<void, CreateIssuerRequest>({
      query: (request) => {
        return {
          method: 'POST',
          url: 'credential/issuers',
          body: request,
        };
      },
      invalidatesTags: ['ISSUERS'],
    }),
    updateIssuer: builder.mutation<void, UpdateIssuerRequest>({
      query: (request) => {
        return {
          method: 'PUT',
          url: 'credential/issuers',
          body: request,
        };
      },
      invalidatesTags: ['ISSUERS'],
    }),
    getIssuersByCompany: builder.query<GetIssuersResponse, string>({
      query: (companyUid: string) => {
        return {
          method: 'GET',
          url: `credential/issuers/companies/${companyUid}`,
        };
      },
      providesTags: ['ISSUERS'],
    }),
    createIssuerForCompany: builder.mutation<
      DefaultResponse,
      CreateIssuerForCompanyRequest
    >({
      query: (request) => {
        return {
          method: 'POST',
          url: 'credential/issuers/companies',
          body: request,
        };
      },
      invalidatesTags: ['ISSUERS'],
    }),
  }),
});

export const {
  useGetIssuersQuery,
  useCreateIssuerMutation,
  useUpdateIssuerMutation,
  useLazyGetIssuersByCompanyQuery,
  useCreateIssuerForCompanyMutation,
} = issuersApi;

export const issuersMiddleware = issuersApi.middleware;

export default issuersApi.reducer;
