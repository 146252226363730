import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import * as Yup from 'yup';
import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ErrorResponse } from 'src/shared/models';
import Input from 'src/shared/components/Input/Input';
import Select from 'src/shared/components/Select/Select';
import DatePicker from 'src/shared/components/DatePicker/DatePicker';

import { UserRoles } from 'src/features/auth/models';
import { State, store } from 'src/features/store/store';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { CompanyUser as CompanyUserType } from 'src/features/companies/models';
import { COMPANIES } from 'src/features/companies/slices/companiesSlice';
import {
  useCreateUserProfileMutation,
  useUpdateUserProfileMutation,
} from 'src/features/companies/api/companiesApi';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';

import './CompanyUser.scss';

export type CompanyInfoTypes = 'CREATE' | 'EDIT';

type CompanyInfoProps = {
  type?: CompanyInfoTypes;
};

const ROLES_OPTIONS: { label: string; value: string }[] = Object.keys(
  UserRoles
).map((role) => {
  return {
    label: role,
    value: role,
  };
});

type CompanyUserValuesType = Pick<
  CompanyUserType,
  | 'userUid'
  | 'firstName'
  | 'lastName'
  | 'companyUid'
  | 'email'
  | 'phone'
  | 'roles'
> & {
  birthDate: string;
  password: string;
  createdAt?: string;
};

const INITIAL_VALUES: CompanyUserValuesType = {
  userUid: '',
  companyUid: '',
  email: '',
  roles: [],
  password: '',
  firstName: '',
  lastName: '',
  birthDate: '',
  phone: '',
};

const addCompanyUserValidation = (type: CompanyInfoTypes) => {
  const object = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email format')
      .required('This field is required'),
    password: Yup.string().required('This field is required'),
    firstName: Yup.string().required('This field is required'),
    lastName: Yup.string().required('This field is required'),
    roles: Yup.array().min(1, 'This field is required'),
  });
  return type === 'EDIT' ? object.omit(['password']) : object;
};

const CompanyUser = ({ type = 'CREATE' }: CompanyInfoProps) => {
  const { id } = useParams();
  const [createUser, creatingResult] = useCreateUserProfileMutation();
  const [updateUser, updatingResult] = useUpdateUserProfileMutation();
  const closeModal = () => {
    store.dispatch(changeModal(null));
  };
  const currentUser = useSelector((s: State) => s[COMPANIES].user);

  const getBirthdate = (date: string) =>
    !date
      ? dayjs(new Date()).format('YYYY-MM-DD')
      : dayjs(date).format('YYYY-MM-DD');

  const onCreate = (values: CompanyUserValuesType) => {
    const birthDate = getBirthdate(values.birthDate);
    id &&
      createUser({
        ...values,
        userUid: uuidv4(),
        companyUid: id,
        birthDate,
      });
  };

  const onUpdate = (values: CompanyUserValuesType) => {
    const birthDate = getBirthdate(values.birthDate);
    updateUser({
      ...values,
      birthDate,
    });
  };

  useEffect(() => {
    if (creatingResult.isSuccess) {
      closeModal();
      toast.success('Created successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [creatingResult.isSuccess]);

  useEffect(() => {
    if (updatingResult.isSuccess) {
      closeModal();
      toast.success('Updated successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [updatingResult.isSuccess]);

  useEffect(() => {
    if (creatingResult.isError) {
      toast.error(
        `Creating error: ${
          (creatingResult.error as ErrorResponse).data.message
        }`,
        {
          containerId: CONTAINER_ID_ACTION,
        }
      );
    }
  }, [creatingResult.error, creatingResult.isError]);

  useEffect(() => {
    if (updatingResult.isError) {
      toast.error(
        `Updating error: ${
          (updatingResult.error as ErrorResponse).data.message
        }`,
        {
          containerId: CONTAINER_ID_ACTION,
        }
      );
    }
  }, [updatingResult.error, updatingResult.isError]);

  const initialFormValues = useMemo<CompanyUserValuesType>(() => {
    return type === 'EDIT' && currentUser
      ? {
          ...currentUser,
          password: '',
        }
      : INITIAL_VALUES;
  }, [currentUser, type]);

  return (
    <Dialog
      open={true}
      maxWidth="lg"
      fullWidth
      PaperProps={{ style: { overflowY: 'visible' } }}
    >
      <div className="modal__header">
        <DialogTitle maxWidth="1000px">
          {type === 'EDIT' ? 'Change user' : 'Create user'}
        </DialogTitle>
      </div>

      <Formik
        initialValues={initialFormValues}
        onSubmit={type === 'EDIT' ? onUpdate : onCreate}
        validationSchema={addCompanyUserValidation(type)}
      >
        <Form className="add-recipient-form">
          <DialogContent>
            <div className="add-recipient-form__inputs-container">
              <Input name="email" label="Email" placeholder="Email" required />
              <Input
                name="firstName"
                label="First Name"
                placeholder="First Name"
                required
              />
              <Input
                name="lastName"
                label="Last Name"
                placeholder="Last Name"
                required
              />
            </div>
            <div className="add-recipient-form__inputs-container company-user-form__inputs-container">
              <Select
                name="roles"
                label="Roles"
                options={ROLES_OPTIONS}
                placeholder="Choose one"
                required
                multiple
              />
              {type === 'EDIT' ? null : (
                <Input
                  name="password"
                  label="Password"
                  placeholder="Password"
                  className="input-container_short"
                  required
                />
              )}
            </div>
            <div className="add-recipient-form__inputs-container">
              <DatePicker
                name="birthDate"
                label="Birthdate"
                format="YYYY-MM-DD"
              />
              <Input name="phone" label="Phone" placeholder="Phone" />
            </div>
          </DialogContent>

          <DialogActions>
            <Button onClick={closeModal} color="secondary">
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              {type === 'EDIT' ? 'Change' : 'Create'}
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default CompanyUser;
