import { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';
import { Breadcrumbs, Button } from '@mui/material';
import { MRT_ColumnDef, MRT_PaginationState } from 'material-react-table';

import { paths } from 'src/app/routes';
import Page from 'src/layouts/BaseLayout/components/Page/Page';

import Card from 'src/shared/components/Card/Card';
import Input from 'src/shared/components/Input/Input';
import Table from 'src/shared/components/Table/Table';
import { DATE_WITHOUT_TIMEZONE } from 'src/shared/utils';

import { useCheckAuthorization } from 'src/features/hooks';
import {
  useGetCampaignQuery,
  useGetCampaignUsersQuery,
} from 'src/features/campaigns/api/campaignsApi';

import './CampaignDetailed.scss';

const SIZE = 10;

const CampaignDetailed = () => {
  const { id } = useParams();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: SIZE,
  });
  const { data: campaign } = useGetCampaignQuery(id as string, {
    skip: !id,
  });
  const {
    data: users,
    isLoading: isUsersLoading,
    isFetching: isUsersFetching,
  } = useGetCampaignUsersQuery(
    {
      campaignUid: campaign?.uid ?? '',
      page: pagination.pageIndex,
      size: pagination.pageSize,
    },
    {
      skip: !campaign?.uid,
    }
  );

  useCheckAuthorization();

  const columns: MRT_ColumnDef[] = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'Email',
        accessorKey: 'email',
      },
    ],
    []
  );

  return (
    <Page>
      <div className="campaign-details">
        <Breadcrumbs>
          <Link className="create-template__bread-crumbs" to={paths.campaigns}>
            NCap™ Engage
          </Link>
          <h1>{campaign?.title ?? ''}</h1>
        </Breadcrumbs>

        <Card className="campaign-details__card">
          <Formik
            initialValues={campaign ?? {}}
            onSubmit={(values) => console.log(values)}
            enableReinitialize
          >
            <Form className="campaign-details__container">
              <div className="campaign-details__info">
                <Input
                  name="title"
                  label="Campaign title"
                  placeholder="Write your title"
                  disabled
                />
                <Input
                  name="message"
                  label="Campaign message"
                  placeholder="Write your message"
                  disabled
                />
                <div className="campaign-details__info-field">
                  <h4 className="campaign-details__info-header">Status</h4>
                  {campaign?.status}
                </div>
                <div className="campaign-details__info-field">
                  <h4 className="campaign-details__info-header">Created</h4>
                  {dayjs(campaign?.createdAt).format(DATE_WITHOUT_TIMEZONE)}
                </div>
                <div className="campaign-details__info-field">
                  <h4 className="campaign-details__info-header">Published</h4>
                  {campaign?.publishedAt
                    ? dayjs(campaign?.publishedAt).format(DATE_WITHOUT_TIMEZONE)
                    : ''}
                </div>
                <div className="campaign-details__info-field">
                  <h4 className="campaign-details__info-header">Open Rate</h4>
                  {campaign?.openRate}
                </div>
              </div>
              <div>
                <h3 className="campaign-details__users-header">
                  {`Target audience (${campaign?.totalAmountOfUsers ?? 0})`}
                </h3>
                <Table
                  columns={columns}
                  data={users?.data ?? []}
                  maxHeight="600px"
                  manualPagination
                  onPaginationChange={setPagination}
                  rowCount={users?.count ?? 0}
                  state={{
                    pagination,
                    isLoading: isUsersLoading,
                    showProgressBars: isUsersFetching,
                  }}
                />
              </div>
            </Form>
          </Formik>

          <div className="campaign-details__buttons">
            {/* <Button variant="outlined" color="error">
              Delete Campaign
            </Button>
            <Button variant="outlined" color="secondary">
              Duplicate
            </Button> */}
            <Button variant="contained" component={Link} to={paths.campaigns}>
              Back to Campaign List
            </Button>
          </div>
        </Card>
      </div>
    </Page>
  );
};

export default CampaignDetailed;
