import { useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';

import Input from 'src/shared/components/Input/Input';
import Select from 'src/shared/components/Select/Select';
import { DATE_FORMAT, countries } from 'src/shared/utils';
import TextArea from 'src/shared/components/TextArea/TextArea';
import DatePicker from 'src/shared/components/DatePicker/DatePicker';
import PicturePicker from 'src/shared/components/PicturePicker/PicturePicker';

import { State, store } from 'src/features/store/store';
import {
  DISPLAY_NAME_FIELDNAME,
  LabelTypes,
  RECIPIENT_INITIAL_VALUES,
} from 'src/features/templates/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { TEMPLATES } from 'src/features/templates/slices/templatesSlice';
import { CredentialRecipientDetails } from 'src/features/credentials/models';
import {
  addCredentialDetails,
  CREDENTIALS,
  editCredentialDetailByIndex,
} from 'src/features/credentials/slices/credentialsSlice';

import './AddCredentialRecipient.scss';

export const enum AddRecipientTypes {
  CREATE,
  EDIT,
}

type AddRecipientProps = {
  type?: AddRecipientTypes;
};

const NATIONALITY_OPTIONS: { label: string; value: string }[] = countries;

const getField = (key: string, type: LabelTypes) => {
  switch (type) {
    case LabelTypes.TEXT:
      return <TextArea name={key} label={key} key={key} required />;
    case LabelTypes.SHORT_TEXT:
      return <Input name={key} label={key} key={key} required />;
    case LabelTypes.NUMBER:
      return <Input name={key} label={key} key={key} type="number" required />;
    case LabelTypes.BOOLEAN:
      return (
        <Input name={key} label={key} key={key} type="checkbox" required />
      );
    case LabelTypes.DATE:
      return <DatePicker name={key} label={key} key={key} required />;
    case LabelTypes.IMAGE:
      return <PicturePicker name={key} title={key} key={key} required />;
    default:
      return <></>;
  }
};

const AddCredentialRecipient = ({
  type = AddRecipientTypes.CREATE,
}: AddRecipientProps) => {
  const currentRecipient = useSelector(
    (s: State) => s[CREDENTIALS].currentRecipient
  );
  const template = useSelector((s: State) => s[TEMPLATES].template);
  const renderParamsFieldNames = useMemo(
    () =>
      template && template.designTemplateUid !== ''
        ? Object.keys(template.renderParams).filter(
            (name) =>
              !Object.keys(RECIPIENT_INITIAL_VALUES).includes(name) &&
              template.renderParams[name].fieldType !== LabelTypes.FIXED_TEXT &&
              name !== DISPLAY_NAME_FIELDNAME
          )
        : [],
    [template]
  );

  const initialFormValues = useMemo<CredentialRecipientDetails>(
    () =>
      type === AddRecipientTypes.EDIT && currentRecipient
        ? currentRecipient
        : {
            ...RECIPIENT_INITIAL_VALUES,
            ...Object.fromEntries(
              renderParamsFieldNames.map((name) => {
                return [name, ''];
              })
            ),
          },
    [currentRecipient, renderParamsFieldNames, type]
  );

  const renderFields = () => {
    if (template?.designTemplateUid !== '' && template?.renderParams) {
      return renderParamsFieldNames.map((key) => {
        return getField(
          key,
          template.renderParams[key].fieldType as LabelTypes
        );
      });
    }
    return <></>;
  };

  const dateFieldsNames = template
    ? Object.keys(template.renderParams).filter(
        (name) =>
          (template.renderParams[name].fieldType as LabelTypes) ===
          LabelTypes.DATE
      )
    : [];

  const fixedFieldsNames = template
    ? Object.keys(template.renderParams).filter(
        (name) =>
          (template.renderParams[name].fieldType as LabelTypes) ===
          LabelTypes.FIXED_TEXT
      )
    : [];

  const renderParamsValidation = Object.fromEntries(
    renderParamsFieldNames.map((name) => {
      return [name, Yup.mixed().required('This field is required')];
    })
  );

  const addRecipientValidation = Yup.object().shape(
    {
      firstName: Yup.string().required('This field is required'),
      lastName: Yup.string().required('This field is required'),
      email: Yup.string()
        .email('Invalid email format')
        .ensure()
        .when('secondaryIdentifier', {
          is: '',
          then: (schema) =>
            schema.required('This or Internal Identifier field is required'),
        }),
      secondaryIdentifier: Yup.string()
        .ensure()
        .when('email', {
          is: '',
          then: (schema) => schema.required('This or Email field is required'),
        }),
      issuedOn: Yup.string().required('This field is required'),
      ...renderParamsValidation,
    },
    [['email', 'secondaryIdentifier']]
  );

  const closeModal = () => {
    store.dispatch(changeModal(null));
  };

  const onSubmit = (values: CredentialRecipientDetails) => {
    const dateValues = Object.fromEntries(
      dateFieldsNames.map((name) => {
        if (name in values && values[name]) {
          return [
            name,
            dayjs(
              values[name] as string | number | Date | Dayjs,
              DATE_FORMAT
            ).format(DATE_FORMAT),
          ];
        }
        return [];
      })
    );
    const fixedTextValues = Object.fromEntries(
      fixedFieldsNames.map((name) => {
        if (template && template?.renderParams[name]) {
          return [name, template?.renderParams[name].fieldLabel];
        }
        return [];
      })
    );

    const displayName =
      template &&
      Object.keys(template.renderParams).includes(DISPLAY_NAME_FIELDNAME)
        ? { [DISPLAY_NAME_FIELDNAME]: `${values.firstName} ${values.lastName}` }
        : undefined;

    const details: CredentialRecipientDetails = {
      ...values,
      issuedOn: dayjs(values.issuedOn, DATE_FORMAT).format(DATE_FORMAT),
      birthdate: values.birthdate
        ? dayjs(values.birthdate, DATE_FORMAT).format(DATE_FORMAT)
        : undefined,
      expires: values.expires
        ? dayjs(values.expires, DATE_FORMAT).format(DATE_FORMAT)
        : undefined,
      ...dateValues,
      ...fixedTextValues,
      ...displayName,
    };
    type === AddRecipientTypes.EDIT && details.index !== undefined
      ? store.dispatch(
          editCredentialDetailByIndex({ ...details, id: details.index })
        )
      : store.dispatch(addCredentialDetails([details]));
    store.dispatch(changeModal(null));
  };

  return (
    <Dialog open={true} maxWidth="lg" fullWidth onClose={closeModal}>
      <div className="modal__header">
        <DialogTitle maxWidth="1000px">
          {type === AddRecipientTypes.EDIT ? 'Change' : 'Add recipient'}
        </DialogTitle>
      </div>

      <Formik
        initialValues={initialFormValues}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={addRecipientValidation}
      >
        <Form className="add-recipient-form">
          <DialogContent>
            <div className="add-recipient-form__inputs-container">
              <Input
                name="firstName"
                label="Name"
                placeholder="Name"
                required
              />
              <Input
                name="lastName"
                label="Last name"
                placeholder="Last name"
                required
              />
            </div>
            <div className="add-recipient-form__inputs-container">
              <Input name="email" label="Email" placeholder="Email" required />
              <Input
                name="secondaryIdentifier"
                label="Internal Identifier"
                placeholder="Number"
                required
              />
            </div>
            <div className="add-recipient-form__inputs-container">
              <Select
                name="country"
                label="Nationality"
                options={NATIONALITY_OPTIONS}
                placeholder="Choose one"
              />
              <Input
                name="countryId"
                label="National ID number"
                placeholder="Ex.: 12345A"
              />
              <DatePicker name="birthdate" label="Birthdate" />
            </div>
            <div>
              <h2 className="credential-details-form__subheader">
                Credential details
              </h2>
              <div className="credential-details-form__dates">
                <DatePicker name="issuedOn" label="Issuance Date" required />
                <Input
                  name="externalIdentifier"
                  label="Credential Identifier"
                  placeholder="Number"
                />
                <DatePicker name="expires" label="Expiry Date" />
              </div>
            </div>
            {renderParamsFieldNames.length ? (
              <div>
                <h2 className="credential-details-form__subheader">
                  Template fields
                </h2>
                <div className="credential-details-form__template-fields">
                  {renderFields()}
                </div>
              </div>
            ) : null}
          </DialogContent>

          <DialogActions>
            <Button onClick={closeModal} color="secondary">
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              {type === AddRecipientTypes.EDIT ? 'Change' : 'Add'}
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default AddCredentialRecipient;
